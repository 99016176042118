.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.wallet {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 500px; */
}

.wallet-wraper {
  min-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.waller-criteria {
  margin-top: 25px;
  background: var(--new-main-clr);
  border-radius: 1000px;
  color: rgb(0, 0, 0);
  width: 100%;
  color: white;
  padding: 20px;
}

.waller-criteria p {
  text-align: center;
}

.incdec {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 20px auto;
}

.incdec input {
  width: 125px;
  height: 125px;
  background: transparent;
  color: var(--new-main-clr);
  border-radius: 50%;
  text-align: center;
  margin: 0px 20px;
  font-size: 20px;
  font-weight: bold;
  outline: 3px solid var(--new-main-clr)
}

.incdec .small {
  margin-top: 0px;
}

.opacity-5 {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn:disabled {
  color: white;
  background: var(--new-body-clr);

  border: 2px solid var(--new-main-clr);
  cursor: not-allowed;
}